<template>
    <div class="not-found">
        <h1>404</h1>

        <p v-html="notFoundContent.text"></p>

        <img src="/img/notfound/nopower.png" alt="">

        <router-link to="/" v-html="notFoundContent.buttonText" class="button"></router-link>
    </div>
</template>

<script>

export default {
    name: 'NotFound',
    computed: {
        notFoundContent() {
            return this.$i18n.messages.notfound
        },
    },
    mounted() {

    },
}

</script>

<style lang="scss">
.not-found {
    height: 100vh;

    h1 {
        font-size: 56px;
    }

    p {
        width: fit-content;
        margin: 0 auto 24px;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
    }

    img {
        width: 100%;
        margin-bottom: 24px;
    }

    a {
        font-size: 16px;
        font-weight: 700;
    }
}
</style>
